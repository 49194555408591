@import url(./keyframes.scss);

@media screen and (max-width: 712px) {
  .App {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    color: #333;
    background-color: rgba(255, 247, 214, 0.6);

    .top {
      display: none;
    }

    .center {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .left {
        display: flex;
        flex-direction: column;
        padding: 0.6rem;
        margin-bottom: 36vh;
        span {
          font-weight: bold;
          padding: 0.2rem 0;
        }
        span:nth-child(1) {
          text-align: center;
          display: inline-block;
          vertical-align: middle;
          line-height: 4rem;
          font-size: 4rem;
          margin-bottom: 0.2rem;
        }
        span:nth-child(2) {
          display: inline-block;
          vertical-align: middle;
          line-height: 4rem;
          font-size: 2rem;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3 {
          font-size: 1.6rem;
          transform: translateX(-1rem);
        }

        span {
          font-size: 1rem;
          transform: translateX(4rem);
          margin: 0.4rem 0 2rem 0;
        }

        a {
          color: #333;
          text-decoration: none;

          &:link {
            color: #333;
          }

          &:visited {
            color: #333;
          }

          &:hover {
            color: #333;
          }

          &:active {
            color: #333;
          }
        }

        .btn {
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          width: 3.2rem;
          height: 3.6rem;
          border: 1px solid #333;
          border-radius: 4rem;
          color: #333;
          padding: 0.2rem 0.4rem;
          line-height: 3.6rem;
          text-align: center;
          font-size: 1.2rem;

          &:hover {
            background-color: rgb(255, 247, 214);
            transform: scale(1.1);
          }
        }
      }
    }

    .bottom {
      display: none;
    }
  }
}

@media screen and (min-width: 712px) {
  .App {
    // min-width: 1200px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-between;
    height: 100vh;
    color: #333;

    div {
      overflow: hidden;
      width: 200vw;
    }

    .top {
      .card {
        float: left;
        animation: slide-left 36s linear infinite;
        &:nth-child(1) {
          margin-left: 2rem;
          // background-color: rgb(214, 214, 214);
        }
      }
    }

    .center {
      width: 100vw;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;

      .left {
        display: flex;
        flex-direction: column;
        margin-left: 12rem;
        span {
          padding: 0.2rem 0;
        }
        span:nth-child(1) {
          display: inline-block;
          vertical-align: middle;
          line-height: 8rem;
          font-size: 8rem;
          margin-bottom: 1.6rem;
        }
        span:nth-child(2) {
          display: inline-block;
          vertical-align: middle;
          line-height: 4rem;
          font-size: 4rem;
        }
      }

      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // margin-left: 12rem;

        h3 {
          font-weight: bold;
          transform: translateX(-4rem);
          font-size: 2.4rem;
          color: #333;
        }

        span {
          transform: translateX(4rem);
          font-size: 1.6rem;
          margin: 2rem 0 0 0;
          color: #999;
        }

        a {
          color: #333;
          text-decoration: none;

          &:link {
            color: #333;
          }

          &:visited {
            color: #333;
          }

          &:hover {
            color: #333;
          }

          &:active {
            color: #333;
          }
        }

        .btn {
          margin: 2rem 0 3rem 0;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          width: 4rem;
          height: 6rem;
          border: 0.3rem solid #333;
          background-color: rgba(255, 247, 214, 0.3);
          border-radius: 8rem;
          color: #333;
          padding: 1rem 2rem;
          line-height: 6rem;
          text-align: center;
          font-size: 1.8rem;
          font-weight: bold;

          &:hover {
            border: 0.3rem solid #333;
            background-color: rgb(255, 247, 214);
            transform: scale(1.1);
          }
        }
      }
    }

    .bottom {
      width: 200vw;
      position: relative;
      right: 100vw;
      .card {
        float: right;
        animation: slide-right 36s linear infinite;
        &:last-child {
          margin-right: 2rem;
          // background-color: rgb(214, 214, 214);
        }
      }
    }

    .card {
      opacity: 1;
      display: inline-block;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      margin: 2rem;
      // min-width: 240px;
      width: 24vw;
      height: 20vh;
      // min-height: 120px;
      border-radius: 2.4rem;
      padding: 1.6rem;
      // box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
      border: 0.3rem solid #333;
      background-color: rgba(255, 247, 214, 0.3);
      // background-color: rgba(51, 170, 255, 0.6);

      h2 {
        line-height: 2rem;
        font-size: 2rem;
        margin-bottom: 1rem;
      }

      p {
        text-indent: 2rem;
        font-size: 1.3rem;
        line-height: 2rem;
      }

      &:hover {
        color: #333;
        opacity: 1;
        box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
        // border: 0.3rem solid rgb(255, 247, 214);
        background-color: rgb(255, 247, 214);
      }
    }
  }
}
