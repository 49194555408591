@keyframes slide-right {
  0% {
    transform: none;
  }
  50% {
    transform: translateX(76vw);
  }
  100% {
    transform: none;
  }
}

@keyframes slide-left {
  0% {
    transform: none;
  }
  50% {
    transform: translateX(-76vw);
  }
  100% {
    transform: none;
  }
}
